@use '../util/' as *;

html {
    font-size: 100%;
    box-sizing: border-box;
}

body {
    background-color: var(--off-white);
    font-family: var(--font-inter);
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

*, 
*::before, 
*::after {
    box-sizing: inherit;
}