.overlay {
  background-color: hsl(250deg 20% 14% / 50%);
  height: 100vh;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 10;
}

.overlay[data-visible="true"] {
  opacity: 0.8;
  visibility: visible;
}

// applied when overlay visible to prevent scroll
.hidden {
  overflow: hidden;
}
