// The designs were created to the following widths:
// - Mobile: 375px
// - Desktop: 1440px

// 375px, 768px, 1440px
$breakpoints-up: (
    'medium': '23.438em',
    'large': '47.9375em',
    'xlarge': '90em',
);

// 374px, 767px, 1439px
$breakpoints-down: (
    'small': '23.375em',
    'medium': '63.9375em',
    'large': '89.938em',
);

@mixin breakpoint($size) {
    @media (min-width: map-get($breakpoints-up, $size)) {
        @content;
    }
}

@mixin breakpoint-down($size) {
    @media (max-width: map-get($breakpoints-down, $size)) {
        @content;
    }
}

