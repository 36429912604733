@use '../util/' as *;

.hero {
    width: 100%;
    @include breakpoint(large) {
        width: calc(100% - 30% - (rem(24)));
    }
    &__img--mobile,
    &__img--desktop {
        margin-bottom: rem(18);
        width: 100%;
    }
    &__img--mobile {
        @include breakpoint(large) {
            display: none;
        }
    }
    &__img--desktop {
        display: none;
        @include breakpoint(large) {
            display: block;
            margin-bottom: rem(24);
        }
    }
    &__headline {
        display: flex;
        flex-direction: column;
        @include breakpoint(large) {
            flex-direction: row;
        }
    }
    h1 {
        @include breakpoint(large) {
            margin-bottom: 0;
            width: 50%;
        }
    }
    &__content {
        @include breakpoint(large) {
            margin-left: rem(40);
            width: 50%;
        }
        p {
            color: var(--dark-grayish-blue);
            margin: 0 auto rem(24);
        }
    }
    &__btn {
        background: var(--soft-red);
        border: none;
        color: var(--off-white);
        cursor: pointer;
        font-size: rem(12);
        letter-spacing: rem(4);
        padding: rem(15) rem(30);
        text-transform: uppercase;
        transition: background 0.2s ease-in-out;
        &:hover {
            background: var(--very-dark-blue);
        }
    }
}