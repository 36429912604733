@use "../util/" as *;

.popular {
  display: flex;
  flex-direction: column;
  @include breakpoint(large) {
    flex-direction: row;
  }
  &__article {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: rem(34);
    @include breakpoint(large) {
      margin-right: rem(24);
    }
  }
  &__image {
    width: rem(120);
    object-fit: contain;
    @include breakpoint(large) {
      width: rem(100);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-left: rem(20);
  }
  &__ranking,
  &__heading {
    margin: 0 0 rem(12) 0;
  }
  &__ranking {
    color: var(--greyish-blue);
  }
  &__heading {
    cursor: pointer;
    &:hover {
      color: var(--soft-red);
    }
  }
  &__subheading {
    color: var(--dark-grayish-blue);
    margin: 0;
  }
}
