@use '../util/' as *;

.nav {
    padding: 0;
    margin: 0;
    &__checkbox {
        cursor: pointer;
        height: rem(32);
        width: rem(32);
        margin: rem(5) auto;
        position: absolute;
        opacity: 0;
        z-index: 999;
        &:checked ~ .nav__container {
            transform: translateX(-100%);
        }
        &:checked ~ .nav__toggle .nav__line:first-child {
            transform: rotate(45deg) translate(-3px, -3px);
        }
        &:checked ~ .nav__toggle .nav__line:nth-child(2) {
            opacity: 0;
        }
        &:checked ~ .nav__toggle .nav__line:nth-child(3) {
            transform: rotate(-45deg) translate(0, -1px);
        }
        @include breakpoint(large) {
            display: none;
        }
    }
    &__toggle {
        display: flex;
        flex-direction: column;
        margin-top: rem(6);
        @include breakpoint(large) {
            display: none;
        }
    }
    &__line {
        background: var(--very-dark-blue);
        border-radius: 5px;
        height: rem(3);
        margin: rem(3) auto;
        opacity: 1;
        position: relative;
        width: rem(32);
        transition: all 0.5s cubic-bezier(0.8,-0.01, 0.12, 0.99);
        z-index: 998;
        &:first-child {
            transform-origin: 0% 0%;
        }
        &:nth-child(3) {
            transform-origin: 0% 100%;
        }
    }
    &__container {
        background: var(--off-white);
        height: 100vh;
        width: rem(250);
        position: absolute;
        left: 100vw;
        top: 0;
        transition: transform 0.5s ease-in-out;
        z-index: 997;
        @include breakpoint(large) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            width: rem(500);
            position: static;
            transform: none;
            transition: none;
        }
    }
    &__navlist {
        padding: min(20vh, rem(130)) 0 0 rem(30);
        @include breakpoint(large) {
            display: flex;
            margin: 0;
            padding: 0;
        }
    }
    &__navlink {
        padding-bottom: rem(30);
        @include breakpoint(large) {
            padding-left: rem(36);
            padding-bottom: 0;
        }
        a {
            color: var(--very-dark-blue);
            cursor: pointer;
            &:hover {
                color: var(--soft-red);
            }
        }
    }
}