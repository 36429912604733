:root {
  --soft-orange: hsl(35, 77%, 62%);
  --soft-red: hsl(5, 85%, 63%);
  --off-white: hsl(36, 100%, 99%);
  --greyish-blue: hsl(233, 8%, 79%);
  --dark-grayish-blue: hsl(236, 13%, 42%);
  --very-dark-blue: hsl(240, 100%, 5%);
}

:root {
  --font-inter: "Inter", sans-serif;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  background-color: var(--off-white);
  font-family: var(--font-inter);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3 {
  line-height: 1.05;
  margin-top: 0;
}

h1 {
  font-size: 2.25rem;
  margin-bottom: 1rem;
}
@media (min-width: 47.9375em) {
  h1 {
    font-size: 3.5rem;
  }
}

h2 {
  font-size: 2rem;
  letter-spacing: 0.0875rem;
}

h3 {
  font-size: 1rem;
  line-height: 1.2;
}

p {
  font-size: 0.875rem;
  letter-spacing: 1.1;
  line-height: 1.7;
}

ul {
  list-style: none;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  margin: 1.5rem auto 1.875rem;
  max-width: 69.375rem;
  padding: 0 1.25rem;
  height: 2.5rem;
}
@media (min-width: 47.9375em) {
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 90em) {
  .container {
    padding: 0;
  }
}

section {
  margin-bottom: 3.75rem;
}

.header {
  display: flex;
  justify-content: space-between;
}
.header img {
  height: 2.5rem;
  margin: 0;
  padding: 0;
}

.nav {
  padding: 0;
  margin: 0;
}
.nav__checkbox {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  margin: 0.3125rem auto;
  position: absolute;
  opacity: 0;
  z-index: 999;
}
.nav__checkbox:checked ~ .nav__container {
  transform: translateX(-100%);
}
.nav__checkbox:checked ~ .nav__toggle .nav__line:first-child {
  transform: rotate(45deg) translate(-3px, -3px);
}
.nav__checkbox:checked ~ .nav__toggle .nav__line:nth-child(2) {
  opacity: 0;
}
.nav__checkbox:checked ~ .nav__toggle .nav__line:nth-child(3) {
  transform: rotate(-45deg) translate(0, -1px);
}
@media (min-width: 47.9375em) {
  .nav__checkbox {
    display: none;
  }
}
.nav__toggle {
  display: flex;
  flex-direction: column;
  margin-top: 0.375rem;
}
@media (min-width: 47.9375em) {
  .nav__toggle {
    display: none;
  }
}
.nav__line {
  background: var(--very-dark-blue);
  border-radius: 5px;
  height: 0.1875rem;
  margin: 0.1875rem auto;
  opacity: 1;
  position: relative;
  width: 2rem;
  transition: all 0.5s cubic-bezier(0.8, -0.01, 0.12, 0.99);
  z-index: 998;
}
.nav__line:first-child {
  transform-origin: 0% 0%;
}
.nav__line:nth-child(3) {
  transform-origin: 0% 100%;
}
.nav__container {
  background: var(--off-white);
  height: 100vh;
  width: 15.625rem;
  position: absolute;
  left: 100vw;
  top: 0;
  transition: transform 0.5s ease-in-out;
  z-index: 997;
}
@media (min-width: 47.9375em) {
  .nav__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 31.25rem;
    position: static;
    transform: none;
    transition: none;
  }
}
.nav__navlist {
  padding: min(20vh, 8.125rem) 0 0 1.875rem;
}
@media (min-width: 47.9375em) {
  .nav__navlist {
    display: flex;
    margin: 0;
    padding: 0;
  }
}
.nav__navlink {
  padding-bottom: 1.875rem;
}
@media (min-width: 47.9375em) {
  .nav__navlink {
    padding-left: 2.25rem;
    padding-bottom: 0;
  }
}
.nav__navlink a {
  color: var(--very-dark-blue);
  cursor: pointer;
}
.nav__navlink a:hover {
  color: var(--soft-red);
}

.hero {
  width: 100%;
}
@media (min-width: 47.9375em) {
  .hero {
    width: calc(70% - 1.5rem);
  }
}
.hero__img--mobile, .hero__img--desktop {
  margin-bottom: 1.125rem;
  width: 100%;
}
@media (min-width: 47.9375em) {
  .hero__img--mobile {
    display: none;
  }
}
.hero__img--desktop {
  display: none;
}
@media (min-width: 47.9375em) {
  .hero__img--desktop {
    display: block;
    margin-bottom: 1.5rem;
  }
}
.hero__headline {
  display: flex;
  flex-direction: column;
}
@media (min-width: 47.9375em) {
  .hero__headline {
    flex-direction: row;
  }
}
@media (min-width: 47.9375em) {
  .hero h1 {
    margin-bottom: 0;
    width: 50%;
  }
}
@media (min-width: 47.9375em) {
  .hero__content {
    margin-left: 2.5rem;
    width: 50%;
  }
}
.hero__content p {
  color: var(--dark-grayish-blue);
  margin: 0 auto 1.5rem;
}
.hero__btn {
  background: var(--soft-red);
  border: none;
  color: var(--off-white);
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 0.25rem;
  padding: 0.9375rem 1.875rem;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out;
}
.hero__btn:hover {
  background: var(--very-dark-blue);
}

.new {
  background: var(--very-dark-blue);
  padding: 1.625rem 1.25rem 0 1.25rem;
}
@media (min-width: 47.9375em) {
  .new {
    margin-left: 1.5rem;
    width: 30%;
  }
}
.new__header {
  color: var(--soft-orange);
  font-size: 1.75rem;
}
.new__container {
  border-bottom: 1px solid var(--dark-grayish-blue);
  margin-bottom: 1.875rem;
}
.new__container:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.new__headline {
  color: var(--off-white);
  cursor: pointer;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}
.new__headline:hover {
  color: var(--soft-orange);
}
.new__subheading {
  color: var(--greyish-blue);
  padding-bottom: 1.625rem;
  margin: 0 auto;
}

.popular {
  display: flex;
  flex-direction: column;
}
@media (min-width: 47.9375em) {
  .popular {
    flex-direction: row;
  }
}
.popular__article {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 2.125rem;
}
@media (min-width: 47.9375em) {
  .popular__article {
    margin-right: 1.5rem;
  }
}
.popular__image {
  width: 7.5rem;
  object-fit: contain;
}
@media (min-width: 47.9375em) {
  .popular__image {
    width: 6.25rem;
  }
}
.popular__content {
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
}
.popular__ranking, .popular__heading {
  margin: 0 0 0.75rem 0;
}
.popular__ranking {
  color: var(--greyish-blue);
}
.popular__heading {
  cursor: pointer;
}
.popular__heading:hover {
  color: var(--soft-red);
}
.popular__subheading {
  color: var(--dark-grayish-blue);
  margin: 0;
}

.overlay {
  background-color: hsla(250, 20%, 14%, 0.5);
  height: 100vh;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 10;
}

.overlay[data-visible=true] {
  opacity: 0.8;
  visibility: visible;
}

.hidden {
  overflow: hidden;
}