@use "../util/" as *;

.container {
  margin: rem(24) auto rem(30);
  max-width: rem(1110);
  padding: 0 rem(20);
  height: rem(40);
  @include breakpoint(large) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @include breakpoint(xlarge) {
    padding: 0;
  }
}

section {
  margin-bottom: rem(60);
}
