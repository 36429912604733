@use "../util/" as *;

h1,
h2,
h3 {
  line-height: 1.05;
  margin-top: 0;
}

h1 {
  font-size: rem(36);
  margin-bottom: rem(16);
  @include breakpoint(large) {
    font-size: rem(56);
  }
}

h2 {
  font-size: rem(32);
  letter-spacing: rem(1.4);
}

h3 {
  font-size: rem(16);
  line-height: 1.2;
}

p {
  font-size: rem(14);
  letter-spacing: 1.1;
  line-height: 1.7;
}

ul {
  list-style: none;
}

a,
a:visited,
a:active {
  text-decoration: none;
}
